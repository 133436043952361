import React from 'react';
import { useNavigate } from 'react-router-dom';

import { capitalize, cleanValue, textToInnerHTML } from '@onesy/utils';
import { Chip, Line, Medias, Type, useSubscription } from '@onesy/ui-react';
import { Booking } from '@onesy/api';

import IconMaterialHangoutMeeting from '@onesy/icons-material-rounded-react/IconMaterialHangoutMeetingW100';
import IconMaterialTrendingUp from '@onesy/icons-material-rounded-react/IconMaterialTrendingUpW100';
import IconMaterialNoteStackW100Rounded from '@onesy/icons-material-rounded-react/IconMaterialNoteStackW100';
import IconMaterialSmartDisplay from '@onesy/icons-material-rounded-react/IconMaterialSmartDisplayW100';
import IconMaterialEventNote from '@onesy/icons-material-rounded-react/IconMaterialEventNoteW100';

import { Color, Input, Inputs, ModalForm } from 'ui';
import { AppService, AuthService } from 'services';
import { formatNumber, getDate } from 'utils';
import { ISignedIn } from 'types';

const Element = React.forwardRef((props: any) => {
  const {
    object: object_
  } = props;

  const navigate = useNavigate();

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn);

  const [object] = React.useState<Booking>(object_);
  const [mode, setMode] = React.useState('update');
  const [tab, setTab] = React.useState('Info');

  const onChangeTab = React.useCallback((valueNew: any) => {
    setTab(valueNew);
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.add.emit({
      ...AppService.pages.add.value,

      open: false
    });
  }, []);

  const onOpen = React.useCallback(page => {
    navigate(page);

    onClose();
  }, []);

  const onChangeMode = React.useCallback((valueNew: any) => {
    setMode(valueNew);
  }, []);

  const getWeekday = React.useCallback(item => {
    const mapWeekday = {
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
      7: 'Sunday'
    };

    return mapWeekday[item];
  }, []);

  const getProperty = React.useCallback((item: any) => {
    const customer = signedIn.customer;

    const value = item.value || item.clients?.[customer?.id];

    return (
      <Input
        key={item.id}

        gap={0.5}

        name={textToInnerHTML(item.name)}

        size='small'
      >
        <Type
          version='b1'
        >
          {item.type === 'number' ? formatNumber(value) : textToInnerHTML(value)}
        </Type>
      </Input>
    );
  }, [signedIn]);

  const inputProps: any = {
    gap: 1,
    gapMain: 1
  };

  const inputsProps: any = {
    gapMain: 2
  };

  const modal: any = {
    Info: <>
      <Inputs
        {...inputsProps}
      >
        <Input
          name='Booking status'

          {...inputProps}
        >
          <Type
            version='b1'

            color={object.status === 'postponed' ? 'warning' : object.status === 'cancelled' ? 'error' : 'inherit'}
          >
            {cleanValue(object.status, { capitalize: true })}
          </Type>
        </Input>

        {!!object?.employees?.length && (
          <Input
            gap={1}

            name='Trainers'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.employees.map(item => textToInnerHTML(item.name)).join(', ')}
            </Type>
          </Input>
        )}

        {!!object?.employee_groups?.length && (
          <Input
            gap={1}

            name='Trainer groups'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.employee_groups.map(item => textToInnerHTML(item.name)).join(', ')}
            </Type>
          </Input>
        )}

        {object?.type === 'class' && (
          <Input
            name='Spaces'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.spaces.unlimited ? 'Unlimited' : object.spaces.value} spaces
            </Type>
          </Input>
        )}

        {!!object.locations?.length && (
          <Input
            name='Location'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.locations?.map(item => item.name).join(', ')}
            </Type>
          </Input>
        )}

        {!!object.rooms?.length && (
          <Input
            name='Room'

            {...inputProps}

            description={object.room_shared ? 'Room is shared' : ''}
          >
            <Type
              version='b1'
            >
              {object.rooms?.map(item => item.name).join(', ')}
            </Type>
          </Input>
        )}

        {object.cancellation_policy && (
          <Input
            name='Cancellation policy'

            description='How long until it can be canceled'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              {object.cancellation_policy.value} {object.cancellation_policy.unit}{object.cancellation_policy.value === 1 ? '' : 's'}
            </Type>
          </Input>
        )}

        {object.repeat.active && (
          <Input
            name='Booking repeat'

            description='When does booking occur'

            {...inputProps}
          >
            <Type
              version='b1'
            >
              Every {object.repeat.value} {object.repeat.unit}{object.repeat.value === 1 ? '' : 's'}{object.repeat.weekdays?.length ? ` on ${object.repeat.weekdays.map(item => getWeekday(item)).join(', ')}` : ''}
            </Type>
          </Input>
        )}

        {object.meeting?.id && (
          <Input
            name={`${textToInnerHTML(object.meeting.name)} meeting`}

            {...inputProps}
          >
            <Chip
              onClick={() => onOpen(`/meetings/${object.meeting?.id}`)}

              start={<IconMaterialHangoutMeeting />}

              size='small'
            >
              Open meeting
            </Chip>
          </Input>
        )}
      </Inputs>
    </>,

    About: <>
      <Inputs
        {...inputsProps}
      >
        {(object.image || object.audio || object.video) && (
          <Medias
            values={[
              ...(object.image ? [{ value: object.image }] : []),
              ...(object.audio ? [{ value: object.audio }] : []),
              ...(object.video ? [{ value: object.video }] : [])
            ] as any}

            align='center'
          />
        )}

        <Input
          name='Description'

          {...inputProps}
        >
          <Type
            version='b1'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(object.description || '')
            }}
          />
        </Input>
      </Inputs>
    </>,

    Progress: <>
      {!!object.properties?.general?.length && (
        <Inputs
          gap={2}

          name='General info'

          description='Booking general info'
        >
          {object.properties.general.map(item => getProperty(item))}
        </Inputs>
      )}

      {!!object.properties?.client?.length && (
        <Inputs
          gap={1.5}

          name='Your personal progress'
        >
          {object.properties.client.map(item => getProperty(item))}
        </Inputs>
      )}
    </>,

    Notes: <>
      <Inputs
        {...inputsProps}
      >
        <Input
          name='Notes'

          {...inputProps}
        >
          <Type
            version='b1'

            dangerouslySetInnerHTML={{
              __html: textToInnerHTML(object.notes_public || '')
            }}
          />
        </Input>
      </Inputs>
    </>
  };

  return (
    <ModalForm
      {...props}

      name={object.name || 'Booking'}

      tab={tab}

      tabs={[
        { name: 'Info', Icon: IconMaterialEventNote },
        { name: 'About', Icon: IconMaterialSmartDisplay },
        { name: 'Progress', Icon: IconMaterialTrendingUp },
        { name: 'Notes', Icon: IconMaterialNoteStackW100Rounded }
      ]}

      onChangeTab={onChangeTab}

      endHeaderLeft={mode === 'update' ? <></> : capitalize(object?.type || 'session')}

      startHeaderRight={<>
        <Line
          direction='row'

          align='center'
        >
          <Input
            name='Starts at'

            gap={0}

            TypeProps={{
              version: 't3'
            }}

            size='small'
          >
            <Type
              version='b1'

              whiteSpace='nowrap'
            >
              {getDate(object.starts_at, 'entire')}
            </Type>
          </Input>

          <Input
            name='Duration'

            gap={0}

            TypeProps={{
              version: 't3'
            }}

            size='small'
          >
            <Type
              version='b1'
            >
              {!!Object.keys(object.duration || {}).length ? `${object.duration?.value} ${object.duration?.unit}` : 'No duration'}
            </Type>
          </Input>
        </Line>
      </>}

      footerLeftEnd={object.meeting?.id && <>
        {object.meeting?.id && (
          <Chip
            onClick={() => onOpen(`/meetings/${object.meeting?.id}`)}

            start={<IconMaterialHangoutMeeting />}

            size='small'
          >
            Open meeting
          </Chip>
        )}
      </>}

      footerRightEnd={(
        <Color
          color={object.color}
        />
      )}

      object={object}

      add

      onChangeMode={onChangeMode}

      onClose={onClose}

      TabsProps={{
        justify: 'center'
      }}
    >
      {modal[tab]}
    </ModalForm>
  );
});

export default Element;

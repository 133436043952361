import { Query } from '@onesy/models';
import OnesySubscription from '@onesy/subscription';
import { IAdditional, IResponse } from '@onesy/sdk/other';
import { Customer } from '@onesy/api';

import { BaseService } from './base';
import { MainService } from 'services';
import { IQuerySubscription } from 'types';

class CustomerService extends BaseService<Customer> {
  public queryObjectsBookings = new OnesySubscription<IQuerySubscription>();
  public queryObjectsAvailables = new OnesySubscription<IQuerySubscription>();
  public queryObjectsAssessments = new OnesySubscription<IQuerySubscription>();
  public queryObjectsGoals = new OnesySubscription<IQuerySubscription>();
  public queryObjectsForms = new OnesySubscription<IQuerySubscription>();
  public customer: Customer;

  get paginationLimit() {
    return 'all';
  }

  public constructor() {
    super('customers');

    this.queryObjectsInit(this.queryObjectsBookings, 'appBookings');
    this.queryObjectsInit(this.queryObjectsAvailables, 'appAvailables');
    this.queryObjectsInit(this.queryObjectsAssessments, 'appAssesments');
    this.queryObjectsInit(this.queryObjectsGoals, 'appGoals');
    this.queryObjectsInit(this.queryObjectsForms, 'appForms');
  }

  public async email(id: string): Promise<IResponse> {
    return MainService.sdk.customers.email(id);
  }

  public async appOverview(body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.customers.appOverview(body, options);
  }

  public async appBookings(body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.customers.appBookings(body, options);
  }

  public async appProgress(options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.customers.appProgress(options);
  }

  public async appAssesments(body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.customers.appAssesments(body, options);
  }

  public async appGoals(body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.customers.appGoals(body, options);
  }

  public async appAvailables(body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.customers.appAvailables(body, options);
  }

  public async appForms(body?: Partial<Query>, options?: IAdditional): Promise<IResponse> {
    return MainService.sdk.customers.appForms(body, options);
  }

}

export default new CustomerService();

import React from 'react';

import { capitalize, stringToColor, textToInnerHTML } from '@onesy/utils';
import { Line, Medias, Properties, RoundProgress, Type, useMediaQuery, useSnackbars } from '@onesy/ui-react';
import { classNames, style, useOnesyTheme } from '@onesy/style-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { IWorkout } from '@onesy/api';

import { Input, Inputs, NoResults } from 'ui';
import { WorkoutService } from 'services';
import { getErrorMessage } from 'utils';
import Exercise from './ExerciseRead';

const useStyle = style(theme => ({
  root: {

  },

  rootNotLoaded: {
    minHeight: '40vh'
  },

  item: {
    padding: 16
  }
}), { name: 'onesy-Workout' });

export interface IWorkoutElement extends IBaseElement {
  object: IWorkout;
}

const Element: React.FC<IWorkoutElement> = React.forwardRef((props, ref: any) => {
  const {
    object: objectProps,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useOnesyTheme();
  const snackbars = useSnackbars();

  const touch = useMediaQuery('(pointer: coarse)');
  const mobile = useMediaQuery('(max-width: 1024px)');

  const [object, setObject] = React.useState<IWorkout>();
  const [loaded, setLoaded] = React.useState(false);

  const init = React.useCallback(async () => {
    const result = await WorkoutService.get(objectProps?.id);

    if (result.status >= 400) {
      snackbars.add({
        primary: getErrorMessage(result),
        color: 'error'
      });
    }
    else {
      setObject(result.response.response);
    }

    setLoaded(true);
  }, [objectProps]);

  React.useEffect(() => {
    if (!object || (objectProps && objectProps.id !== object.id)) init();
  }, [objectProps?.id]);

  const stepsUI = React.useMemo(() => {

    return <>
      {object.steps?.map((item, index) => {
        const palette = theme.methods.color(item.type === 'exercise' ? stringToColor(item.exercise?.name || 'exercise') : '#00b9ff');

        return (
          <Input
            key={item.id}

            name={`Step ${index + 1} ${capitalize(item.type)}: ${item.type === 'rest' ? ` ${item.duration.value} ${item.duration.unit}${item.duration.value === 1 ? '' : 's'}` : ` ${item.exercise?.name || 'Not provided'}`}`}

            size='large'

            openDefault

            expand={!(mobile || touch)}

            className={classNames([
              classes.item
            ])}

            style={{
              background: palette[theme.palette.light ? 90 : 20]
            }}
          >
            <Properties
              values={[
                ...(item.type === 'exercise' ? [
                  {
                    name: 'Repeat',
                    value: `${item.repeat} time${item.repeat === 1 ? '' : 's'}`
                  }
                ] : []),
                {
                  name: 'Duration',
                  value: `${item.duration.value} ${item.duration.unit}${item.duration.value === 1 ? '' : 's'}`
                }
              ]}

              {...propertiesProps}
            />

            {item.exercise && (
              <Exercise
                key={index}

                object={item.exercise}
              />
            )}
          </Input>
        );
      })}
    </>;
  }, [mobile, touch]);

  const media = [object?.video, object?.image, object?.audio].filter(Boolean);

  const propertiesProps: any = {
    rowGap: 0.5,
    version: 'row',
    size: 'large'
  };

  return (
    <Line
      ref={ref}

      gap={4}

      align={loaded ? 'flex-start' : 'center'}

      justify={loaded ? 'flex-start' : 'center'}

      flex

      fullWidth

      className={classNames([
        'onesy-Workout',

        className,
        classes.root,
        !loaded && classes.rootNotLoaded
      ])}

      {...other}
    >
      {!loaded && <RoundProgress />}

      {loaded && !object && <NoResults />}

      {loaded && object && <>
        <Line
          gap={2.5}

          fullWidth
        >
          {!!media?.length && (
            <Medias
              align='center'

              values={media.map((item: any) => ({
                value: (item?.id || item?.url || item?.urlEmbed) ? item : undefined
              }))}

              size='large'
            />
          )}

          <Properties
            values={[
              {
                name: 'Time of day',
                value: capitalize(object?.day_time)
              },
              {
                name: 'Duration',
                value: `${object.duration.value} ${object.duration.unit}${object.duration.value === 1 ? '' : 's'}`
              }
            ]}

            {...propertiesProps}
          />

          {object.description && (
            <Type
              version='b2'

              whiteSpace='pre-wrap'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(object.description)
              }}
            />
          )}
        </Line>

        <Inputs
          gap={3}

          gapMain={2.5}

          name='Steps'

          size='large'
        >
          {stepsUI}
        </Inputs>
      </>}
    </Line>
  );
});

export default Element;

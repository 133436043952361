import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { getRedirectTo } from '@onesy/utils';
import { NotFound, useSubscription } from '@onesy/ui-react';

import { getRootPage, lazy, wrapper } from 'utils';
import { MainService, AuthService } from 'services';
import { ISignedIn } from 'types';

import RootAuth from './pages/auth/Root';
import SignIn from './pages/auth/SignIn';

const RootMain = lazy(React.lazy(() => import('./pages/main/Root')));

const Overview = lazy(React.lazy(() => import('./pages/main/Overview/Overview')));
const Calendar = lazy(React.lazy(() => import('./pages/main/Calendar')));
const Available = lazy(React.lazy(() => import('./pages/main/Available')));
const Progress = lazy(React.lazy(() => import('./pages/main/Progress')));
const WorkoutPlans = lazy(React.lazy(() => import('./pages/main/WorkoutPlans')));
const NutritionPlans = lazy(React.lazy(() => import('./pages/main/NutritionPlans')));
const Assessments = lazy(React.lazy(() => import('./pages/main/Assessments/Assessments')));
const Goals = lazy(React.lazy(() => import('./pages/main/Goals/Goals')));
const Forms = lazy(React.lazy(() => import('./pages/main/Forms/Forms')));
const Review = lazy(React.lazy(() => import('./pages/main/Review')));

const Chats = lazy(React.lazy(() => import('./pages/main/Chats/Chats')));

const Meetings = lazy(React.lazy(() => import('./pages/main/Meetings/Meetings')));
const Meeting = lazy(React.lazy(() => import('./pages/main/Meetings/Meeting')));

const Routing = React.forwardRef((props: any, ref: any) => {
  const {
    ...other
  } = props;

  const navigate = useNavigate();

  const signInMethod = (value: any, setResponse: any) => {
    // Sign out
    if (refs.signedIn.current && value === null) {
      // redirect to
      const redirect_to = getRedirectTo();

      let to = `/sign-in`;

      if (AuthService.redirect) to += `?redirect_to=${redirect_to}`;

      navigate(to);
    }

    setResponse([value]);
  };

  const signedIn = useSubscription<ISignedIn>(AuthService.signedIn, signInMethod);
  const initial = useSubscription(MainService.initial);

  const refs = {
    signedIn: React.useRef(signedIn)
  };

  refs.signedIn.current = signedIn;

  // Only show routes
  // when we have all the info
  // about the auth, user
  if (!initial) return null;

  // const is = signedIn?.user?.is;

  return (
    <Routes
      ref={ref}

      {...other}
    >
      {AuthService.isAuthenticated && (
        <Route
          element={wrapper(RootMain)}
        >
          <Route
            index

            element={wrapper(Overview)}
          />

          <Route
            path='calendar'

            element={wrapper(Calendar)}
          />

          <Route
            path='available'

            element={wrapper(Available)}
          />

          <Route
            path='progress'

            element={wrapper(Progress)}
          />

          <Route
            path='workout-plans'

            element={wrapper(WorkoutPlans)}
          />

          <Route
            path='nutrition-plans'

            element={wrapper(NutritionPlans)}
          />

          <Route
            path='assessments'

            element={wrapper(Assessments)}
          />

          <Route
            path='goals'

            element={wrapper(Goals)}
          />

          <Route
            path='forms'

            element={wrapper(Forms)}
          />

          <Route
            path='review'

            element={wrapper(Review)}
          />

          <Route
            path='/chats'

            element={wrapper(Chats)}
          />

          <Route
            path='/chats/new'

            element={wrapper(Chats)}
          />

          <Route
            path='/chats/:id'

            element={wrapper(Chats)}
          />

          <Route
            path='/meetings'

            element={wrapper(Meetings)}
          />

          <Route
            path='/meetings/:id'

            element={wrapper(Meeting)}
          />

          <Route
            path='*'

            element={(
              <NotFound
                to={getRootPage(signedIn)}

                ButtonProps={{
                  name: 'Back to home'
                }}
              />
            )}
          />
        </Route>
      )}

      {!AuthService.isAuthenticated && (
        <Route
          element={wrapper(RootAuth)}
        >
          <Route
            path='/sign-in'

            element={wrapper(SignIn)}
          />

          <Route
            path='*'

            element={<Navigate to='/sign-in' />}
          />
        </Route>
      )}
    </Routes>
  );
});

export default Routing;

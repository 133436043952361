import React from 'react';

import { cleanValue, stringToColor, textToInnerHTML } from '@onesy/utils';
import { Line, ListItem, Medias, Properties, Tab, Tabs, Type } from '@onesy/ui-react';
import { classNames, style, useOnesyTheme } from '@onesy/style-react';
import { IBaseElement } from '@onesy/ui-react/types';
import { INutritionPlan, INutritionPlanMeal, INutritionPlanMealType, INutritionPlanWeekDay } from '@onesy/api';

import IconMaterialMenuBook from '@onesy/icons-material-rounded-react/IconMaterialMenuBookW100';

import { Divider, Input, Inputs, ModalForm, NoResults, View } from 'ui';
import { AppService } from 'services';
import { formatNumber, propertyCalories } from 'utils';
import Recipe from './RecipeRead';

const useStyle = style(theme => ({
  root: {
    '& .onesy-Tab-root': {
      padding: '8px 10px'
    }
  },

  week: {
    flex: '0 0 auto',
    padding: 1,
    overflow: 'auto hidden'
  },

  day: {
    border: `1px solid ${theme.palette.light ? '#dadada' : '#575757'}`,
    height: 'unset',
    marginLeft: -1,
    marginTop: -1,
    minHeight: 400,
    minWidth: 400,
    padding: 12
  },

  recipes: {
    maxHeight: 145,
    overflow: 'hidden auto'
  },

  rest: {
    opacity: 0.4,
    cursor: 'default',
    pointerEvents: 'none'
  }
}), { name: 'onesy-NutritionPlan' });

export interface INutritionPlanElement extends IBaseElement {
  object: INutritionPlan;
}

const Element: React.FC<INutritionPlanElement> = React.forwardRef((props, ref: any) => {
  const {
    object,

    className,

    ...other
  } = props;

  const { classes } = useStyle();

  const theme = useOnesyTheme();

  const [open, setOpen] = React.useState<any>({ open: false });
  const [tabs, setTabs] = React.useState<any>({});

  const media = [object?.video, object?.image, object?.audio].filter(Boolean);

  const onOpen = React.useCallback((item: any) => {
    setOpen((previous: any) => ({
      ...previous,

      recipe: item,

      open: true
    }));
  }, []);

  const onCloseRecipe = React.useCallback(() => {
    setOpen((previous: any) => ({
      ...previous,

      open: false
    }));
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.addSecondary.emit({
      ...AppService.pages.addSecondary.value,

      open: false
    });
  }, []);

  const getTotalDayMeal = React.useCallback((index: number, indexDay: number, mealType: INutritionPlanMealType) => {
    const weeks = [...object.weeks];

    const week = weeks[index];

    const day = week.days[indexDay] as INutritionPlanWeekDay;

    const meal = day.meals[mealType];

    let value = 0;

    const ingredients = meal?.ingredients || [];

    ingredients.forEach(ingredient => {
      const quantityID = `${week.id}-${day.id}-${meal.id}-${ingredient.id}`;

      value += +((ingredient.nutrition[propertyCalories] / 100) * (object.quantities?.[quantityID] || 0)).toFixed(2);
    });

    return +(value).toFixed(2);
  }, []);

  const getTotalDay = React.useCallback((index: number, indexDay: number) => {
    const weeks = [...object.weeks];

    const week = weeks[index];

    const day = week.days[indexDay] as INutritionPlanWeekDay;

    let value = 0;

    Object.keys(day.meals).forEach((mealType: INutritionPlanMealType) => value += getTotalDayMeal(index, indexDay, mealType));

    return +(value).toFixed(2);
  }, []);

  const getTotalWeek = React.useCallback((index: number) => {
    const weeks = [...object.weeks];

    const week = weeks[index];

    let value = 0;

    week.days.forEach((day, indexDay) => {
      value += getTotalDay(index, indexDay);
    });

    return +(value).toFixed(2);
  }, []);

  const getTotal = React.useCallback(() => {
    const weeks = [...object.weeks];

    let value = 0;

    weeks.forEach((week, index) => {
      value += getTotalWeek(index);
    });

    return +(value).toFixed(2);
  }, []);

  const tabsMealType = React.useMemo(() => {
    return ['breakfast', 'snack-1', 'lunch', 'snack-2', 'dinner'];
  }, []);

  const propertiesProps: any = {
    rowGap: 0.5,
    version: 'row',
    size: 'large'
  };

  return <>
    <ModalForm
      name={object?.name}

      object={object}

      onClose={onClose}

      noWrite
    >
      <Line
        ref={ref}

        gap={4}

        align='flex-start'

        justify='flex-start'

        flex

        fullWidth

        className={classNames([
          'onesy-NutritionPlan',

          className,
          classes.root
        ])}

        {...other}
      >
        <Line
          gap={2.5}

          fullWidth
        >
          {!!media?.length && (
            <Medias
              align='center'

              values={media.map((item: any) => ({
                value: (item?.id || item?.url || item?.urlEmbed) ? item : undefined
              }))}

              size='large'
            />
          )}

          <Properties
            values={[
              ...(object.total_calories !== undefined ? [
                {
                  name: 'Total calories',
                  value: `${formatNumber(Math.round(object.total_calories || 0))} kcal`
                }
              ] : []),

              ...(object.average_calories_month !== undefined ? [
                {
                  name: 'Average calories per month',
                  value: `${formatNumber(Math.round(object.average_calories_month || 0))} kcal`
                }
              ] : []),

              ...(object.average_calories_week !== undefined ? [
                {
                  name: 'Average calories per week',
                  value: `${formatNumber(Math.round(object.average_calories_week || 0))} kcal`
                }
              ] : []),

              {
                name: 'Repeat',
                value: `${object.repeat || 0} times`
              }
            ]}

            {...propertiesProps}
          />

          {object.description && (
            <Type
              version='b1'

              whiteSpace='pre-wrap'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(object.description)
              }}
            />
          )}
        </Line>

        <Inputs
          gap={3}

          gapMain={2.5}

          name='Weeks'

          description={(
            <Type
              version='t1'

              weight={400}
            >
              Total calories <b>{formatNumber(getTotal())}</b> kcal
            </Type>
          )}

          size='large'
        >
          {object.weeks?.map((week, index) => (
            <Input
              key={week.id}

              gap={2}

              name={`Week ${index + 1}`}

              description={(
                <Type
                  version='b1'
                >
                  Total weekly calories <b>{formatNumber(getTotalWeek(index))}</b> kcal
                </Type>
              )}

              size='large'
            >
              {week.rest && (
                <NoResults
                  padding={0}

                  maxWidth={340}

                  size='small'
                >
                  Rest week
                </NoResults>
              )}

              {!week.rest && (
                <Line
                  gap={0}

                  direction='row'

                  align='stretch'

                  fullWidth

                  className={classes.week}
                >
                  {week.days?.map((day, indexDay) => {
                    const tabDayItem = `${week.id}-${day.id}`;
                    const mealType = tabs[tabDayItem] || 'breakfast';
                    const meal = day.meals[mealType] as INutritionPlanMeal;

                    const withValues = !!meal.ingredients?.length;

                    return (
                      <Input
                        name={`Day ${indexDay + 1}`}

                        NameProps={{
                          align: 'flex-start'
                        }}

                        className={classNames([
                          classes.day
                        ])}

                        size='small'
                      >
                        <Line
                          gap={3}

                          fullWidth
                        >
                          {!day.rest ? (
                            <Line
                              gap={0}

                              align='center'

                              fullWidth
                            >
                              <Type
                                name='Description'

                                version='b1'

                                dangerouslySetInnerHTML={{
                                  __html: textToInnerHTML(day.description)
                                }}

                                fullWidth

                                style={{
                                  marginBottom: 20
                                }}
                              />

                              <Line
                                gap={1}

                                fullWidth
                              >
                                <Tabs
                                  value={mealType}

                                  onChange={valueNew => setTabs(previous => ({
                                    ...previous,

                                    [tabDayItem]: valueNew
                                  }))}

                                  size='small'
                                >
                                  {tabsMealType.map(tabMealType => (
                                    <Tab
                                      key={tabMealType}

                                      value={tabMealType}

                                      name={(
                                        <Type
                                          version='l2'
                                        >
                                          {cleanValue(tabMealType, { capitalize: true })}
                                        </Type>
                                      )}
                                    />
                                  ))}
                                </Tabs>

                                <Line
                                  gap={2}

                                  fullWidth
                                >
                                  <Input
                                    name='Ingredients'

                                    weight={600}

                                    size='small'
                                  >
                                    <Line
                                      gap={2}

                                      fullWidth
                                    >
                                      {!withValues && (
                                        <NoResults
                                          padding={40}

                                          maxWidth={240}

                                          size='small'
                                        >
                                          No ingredients
                                        </NoResults>
                                      )}

                                      {withValues && <>
                                        {meal.ingredients?.map((ingredient, indexIngredient) => {
                                          const quantityID = `${week.id}-${day.id}-${meal.id}-${ingredient.id}`;

                                          return (
                                            <ListItem
                                              key={ingredient.id}

                                              color='inherit'

                                              primary={(
                                                <Type
                                                  version='t2'

                                                  weight={400}

                                                  dangerouslySetInnerHTML={{
                                                    __html: textToInnerHTML(ingredient.name)
                                                  }}
                                                />
                                              )}

                                              secondary={(
                                                <Line
                                                  gap={1}

                                                  direction='row'

                                                  wrap='wrap'

                                                  align='center'
                                                >
                                                  <Type
                                                    version='b1'

                                                    weight={300}

                                                    whiteSpace='nowrap'
                                                  >
                                                    {ingredient.nutrition[propertyCalories] / 100}
                                                  </Type>

                                                  <Type
                                                    version='b1'

                                                    weight={100}

                                                    whiteSpace='nowrap'
                                                  >
                                                    kcal / g x
                                                  </Type>

                                                  <Type
                                                    version='b1'

                                                    weight={100}

                                                    style={{
                                                      width: 'auto'
                                                    }}
                                                  >
                                                    {object.quantities?.[quantityID]}
                                                  </Type>

                                                  <Type
                                                    version='b1'

                                                    weight={100}

                                                    whiteSpace='nowrap'
                                                  >
                                                    g =
                                                  </Type>

                                                  <Type
                                                    version='b1'

                                                    weight={300}

                                                    whiteSpace='nowrap'
                                                  >
                                                    {+((ingredient.nutrition[propertyCalories] / 100) * (object.quantities?.[quantityID] || 0)).toFixed(2)} kcal
                                                  </Type>
                                                </Line>
                                              )}

                                              size='small'

                                              Component='div'

                                              noBackground

                                              noPadding
                                            />
                                          );
                                        })}

                                        <Divider size='small' />

                                        <Input
                                          name='Total meal calories'

                                          endName={(
                                            <Type
                                              version='t2'

                                              weight={500}
                                            >
                                              {formatNumber(getTotalDayMeal(index, indexDay, mealType))} kcal
                                            </Type>
                                          )}

                                          size='small'
                                        />
                                      </>}
                                    </Line>
                                  </Input>

                                  {!!meal.recipes?.length && (
                                    <Input
                                      name='Recipes'

                                      weight={600}

                                      size='small'
                                    >
                                      <Line
                                        gap={1}

                                        fullWidth

                                        className={classes.recipes}
                                      >
                                        {meal.recipes.map(recipe => {
                                          const palette = theme.methods.color(stringToColor(recipe?.name || 'recipe'));

                                          return (
                                            <ListItem
                                              start={<IconMaterialMenuBook />}

                                              primary={(
                                                <Type
                                                  version='t2'

                                                  color='default'
                                                >
                                                  {recipe.name}
                                                </Type>
                                              )}

                                              onClick={() => onOpen(recipe)}

                                              size='small'

                                              Component='div'

                                              button

                                              noBackground

                                              style={{
                                                background: palette[theme.palette.light ? 90 : 20]
                                              }}
                                            />
                                          );
                                        })}
                                      </Line>
                                    </Input>
                                  )}
                                </Line>

                                <Divider size='small' />

                                <Input
                                  name='Total daily calories'

                                  endName={(
                                    <Type
                                      version='t2'

                                      weight={500}
                                    >
                                      {formatNumber(getTotalDay(index, indexDay))} kcal
                                    </Type>
                                  )}

                                  size='small'
                                />
                              </Line>
                            </Line>
                          ) : (
                            <Line
                              align='center'

                              fullWidth
                            >
                              <NoResults
                                padding={0}

                                maxWidth={240}

                                size='small'
                              >
                                Rest day
                              </NoResults>
                            </Line>
                          )}
                        </Line>
                      </Input>
                    );
                  })}
                </Line>
              )}
            </Input>
          ))}
        </Inputs>
      </Line>
    </ModalForm>

    <View
      open={open.open}

      onClose={onCloseRecipe}

      minWidth='xl'
    >
      <ModalForm
        name={open.recipe?.name}

        object={object}

        onClose={onCloseRecipe}
      >
        <Recipe
          object={open.recipe}
        />
      </ModalForm>
    </View>
  </>;
});

export default Element;

import React from 'react';

import { debounce, stringToColor } from '@onesy/utils';
import { Avatar, Line, List, ListItem, SmartTextField, Type, useSnackbars } from '@onesy/ui-react';
import { style } from '@onesy/style-react';
import { ISmartTextField } from '@onesy/ui-react/SmartTextField/SmartTextField';

import { OrganizationService } from 'services';
import { getErrorMessage } from 'utils';

const useStyle = style(theme => ({
  menu: {
    width: '100vw',
    maxWidth: 240,
    borderRadius: theme.methods.shape.radius.value(2.5),
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.04)',
    background: theme.palette.light ? theme.palette.color.neutral[99] : theme.palette.color.neutral[20]
  },

  list: {
    maxHeight: 250,
    overflow: 'hidden auto'
  }
}), { name: 'onesy-SmartTextField' });

const Element: React.FC<ISmartTextField> = React.forwardRef((props, ref: any) => {
  const { classes } = useStyle();

  const snackbars = useSnackbars();

  const [loaded, setLoaded] = React.useState(false);
  const [optionsMention, setOptionsMention] = React.useState<any>([]);

  const refs = {
    loaded: React.useRef(loaded),
    props: React.useRef(props)
  };

  refs.loaded.current = loaded;

  refs.props.current = props;

  React.useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1400);
  }, []);

  const onChangeMention = React.useMemo(() => {
    return debounce(async (value: string = '', version: '@') => {
      if (
        !refs.loaded.current ||
        !refs.props.current.mention
      ) return;

      const valueSearch = value?.startsWith('@') ? value.slice(1) : value;

      const response = await OrganizationService.queryUsersPost({
        query: {
          name: valueSearch
        }
      });

      if (response.status >= 400) {
        snackbars.add({
          color: 'error',
          primary: getErrorMessage(response)
        });
      }
      else {
        setOptionsMention(response.response.response?.map((item: any) => ({ ...item, value: item.id })));
      }
    }, 140);
  }, []);

  const mentionLabel = React.useCallback((optionsMentionItems: any, propsLabel: any) => {

    return (
      <Line
        className={classes.menu}
      >
        <List
          size='small'

          className={classes.list}
        >
          {/* Users */}
          {optionsMentionItems?.map((item: any, index: number) => (
            <ListItem
              key={index}

              start={(
                <Avatar
                  color={stringToColor(item.name)}

                  size='small'
                >
                  {item.name?.slice(0, 1)}
                </Avatar>
              )}

              primary={(
                <Type
                  version='l2'
                >
                  {item.name}
                </Type>
              )}

              secondary={(
                <Type
                  version='b2'
                >
                  {item.email}
                </Type>
              )}

              onClick={() => propsLabel.addTag(item)}

              button
            />
          ))}
        </List>
      </Line>
    );
  }, []);

  return (
    <SmartTextField
      ref={ref}

      onChangeMention={onChangeMention}

      optionsMention={optionsMention}

      mentionLabel={mentionLabel}

      {...props}
    />
  );
});

export default Element;

import React from 'react';
import * as ebml from 'ts-ebml';
import { Buffer } from 'buffer';

import { capitalize, clamp, cleanValue, getObjectValue, getUserLocalInfo, innerHTMLToText, is, numberWithCommas, parse, pick, shuffle, textToInnerHTML } from '@onesy/utils';
import { Line, Link, Type } from '@onesy/ui-react';
import { colors } from '@onesy/style-react';
import { add, OnesyDate, diff, format, is as isDate } from '@onesy/date';
import { IContact, IMedia } from '@onesy/api';
import { IResponse } from '@onesy/sdk/other';

import config from 'config';

import { RouteWrapper } from 'ui';
import { ISignedIn } from './types';

window.Buffer = Buffer;

export const colorsDefault = {
  primary: {
    main: '#b6ff00'
  },
  secondary: {
    main: colors.yellow[500]
  },
  tertiary: {
    main: colors.lightblue[500]
  },
  quaternary: {
    main: colors.amber[500]
  }
};

export const FONT_FAMILY = {
  primary: ['Montserrat', 'Helvetica', 'Helvetica Neue', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'sans-serif'].join(', '),
  secondary: ['Outfit', 'Helvetica', 'Helvetica Neue', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'sans-serif'].join(', '),
  tertiary: ['Roboto Mono', 'monospace'].join(', ')
};

export const lazy = (Element: any) => (props: any) => (
  <React.Suspense fallback={<></>}>
    <Element {...props} />
  </React.Suspense>
);

export const wrapper = (Element: any) => (
  <RouteWrapper
    fallback
  >
    <Element />
  </RouteWrapper>
);

export const googleLibrary: any = {
  oauth2: '',
  callback: ''
};

export const getMediaThumbnailURL = (value: IMedia) => {
  return `${config.value.apps.api_media_read.url}/medias/${value?.id}/read?thumbnail=true`;
};

export const getMediaUrl = (value: IMedia, version?: 140 | 480 | 1280 | 1920): string => {
  if (!value) return '';

  if (is('string', value)) return value as unknown as string;

  if (is('string', value?.urlSmall)) return value.urlSmall;

  if (is('string', value?.url)) return value.url;

  if (value?.id) {
    let url = `${config.value.apps.api_media_read.url}/medias/${value?.id}/read`;

    if (version) url += `?version=${version}`;

    return url;
  }

  if (is('string', value.url)) return value.url;

  return '';
};

export const getErrorMessage = (result: IResponse) => result?.response?.meta?.message || 'Error occured';

export const formats = {
  entire: 'DD MMM, YYYY [at] h:mm a',
  date: 'DD MMM, YYYY',
  time: 'h:mm a'
};

export const getDate = (value: OnesyDate | number = OnesyDate.milliseconds, version: 'entire' | 'date' | 'time' | string = 'date') => format(new OnesyDate(value), (formats as any)[version] || version);

export const PAGINATION_LIMIT = 14;

export const LOAD_MORE_LIMIT = 300;

export const getSettings = (signedIn: ISignedIn, value: string) => {
  const result = getObjectValue(signedIn?.organization?.personalization, value);

  return result !== undefined ? result : getObjectValue(signedIn?.user, value);
};

export const mediaToObject = (media: IMedia): any => {
  if (!media) return null;

  const object: Partial<IMedia> = {};

  if (media.id !== undefined) object.id = media.id;

  if (media.name !== undefined) object.name = media.name;

  if (media.mime !== undefined) object.mime = media.mime;

  if (media.duration !== undefined) object.duration = media.duration;

  // if (media.original !== undefined) object.original = media.original;

  if (media.resolution !== undefined) object.resolution = media.resolution;

  // if (media.added_at !== undefined) object.added_at = media.added_at;

  // if (media.updated_at !== undefined) object.updated_at = media.updated_at;

  if (media.meta && ['audio', 'video'].some((item: any) => object?.mime?.startsWith(item))) {
    object.meta = {};

    if (media.meta.resolution !== undefined) object.meta.resolution = media.meta.resolution;

    // if (media.meta.height !== undefined) object.meta.height = media.meta.height;

    // if (media.meta.width !== undefined) object.meta.width = media.meta.width;

    if (media.meta.duration !== undefined) object.meta.duration = media.meta.duration;

    if (media.meta.size !== undefined) object.meta.size = media.meta.size;

    if (media.meta.type !== undefined) object.meta.type = media.meta.type;
  }

  object.url = getMediaUrl(object);

  if (object.mime?.startsWith('image')) object.urlSmall = getMediaUrl(object, 480);

  return object;
};

export const mediaToValue = (value: any) => {
  if (!value) return value;

  const object = mediaToObject(value);

  // only for audio, video 
  if (value.versions && ['audio', 'video'].some((item: any) => object?.mime?.startsWith(item))) object.versions = value.versions.map((item: any) => mediaToObject(item));

  if (value.thumbnails && ['audio', 'video'].some((item: any) => object?.mime?.startsWith(item))) object.thumbnails = value.thumbnails.map((item: any) => mediaToObject(item));

  return object;
};

export const mediasToValue = (value: any) => {
  if (!value) return value;

  if (is('array', value)) return value.map((item: any) => mediaToValue(item));

  if (is('object', value)) return mediaToValue(value);
};

export const NOTIFICATION_VERSIONS = {
  noteMention: 'note-mention',
  taskAssign: 'task-assign'
};

export const addStyle = (value: string, id?: string) => {
  const styleElement = window.document.querySelector(`#${id}`);

  if (styleElement) styleElement.remove();

  const head = window.document.head;

  const style = window.document.createElement('style');

  style.innerHTML = value;

  head.appendChild(style);
};

export const getDeviceAndLocation: any = async () => {
  const device = parse(window.localStorage.getItem('onesy_device-id'));

  const location = await getUserLocalInfo();

  return {
    device,
    location
  };
};

export const menuItemProps = (value: any) => ({
  button: true,

  size: 'small',

  menuCloseOnClick: true,

  ...value,

  primary: (
    <Type
      version='b2'
    >
      {value.primary}
    </Type>
  )
});

export const googleAPILoad = (data: any = {}) => {
  const gapi = (window as any).gapi;

  if (!gapi) return;

  return new Promise((resolve, reject) => {
    const params = {
      client_id: config.value.services.google.client_id,

      ux_mode: 'popup',
      plugin_name: 'onesy',

      ...data
    };

    gapi.load('auth2', () => {
      const GoogleAuth = gapi.auth2.getAuthInstance();

      if (!GoogleAuth) {
        gapi.auth2.init(params).then(
          (response: any) => {
            const signedIn = response?.isSignedIn?.get();

            if (signedIn) resolve(response?.currentUser?.get());
          },
          (error: any) => {
            resolve(false);
          }
        );
      }
      else {
        GoogleAuth.then(
          () => {
            const signedIn = GoogleAuth.isSignedIn?.get();

            if (signedIn) return resolve(GoogleAuth.currentUser.get());
            else return resolve(false);
          },
          (error: any) => {
            resolve(false);
          }
        );
      }
    });
  });
};

export const getParamID = (split = '/', location_ = window.location): any => {
  const id = location_.pathname.split(split).filter(Boolean).slice(-1)[0]?.replaceAll('/', '');

  if (id?.length >= 24 && !['add'].includes(id)) return id;

  return null;
};

export const getElementText = (value: any): string => {
  const element = window.document.createElement('div');

  element.innerHTML = textToInnerHTML(value);

  return (element.innerText || element.textContent) as string;
};

export const importVCards = (value_: string) => {
  const values: any = [];

  let value = value_
    .split('END:VCARD')
    .map((item: any) => item.split(/[\n\r]/).filter((item: any) => !!item && !['BEGIN:VCARD', 'END:VCARD'].includes(item)))
    .filter((item: any) => !!item.length);

  value.forEach(vCard => {
    const object: any = {};

    const properties: any = [];

    vCard.forEach((item: any) => {
      let itemValue = item.trim();

      // Name 
      if (itemValue.startsWith('FN')) object.name = itemValue.replace('FN:', '');
      // Nickname 
      else if (itemValue.startsWith('NICKNAME')) object.nickname = itemValue.replace('NICKNAME:', '');
      // Description 
      else if (itemValue.startsWith('NOTE')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        if (!object.description) {
          itemValue = innerHTMLToText(itemValue);

          object.description = itemValue;
        }
        else {
          properties.push({
            version: 'custom',
            value: itemValue
          });
        }
      }
      // Email 
      else if (itemValue.startsWith('EMAIL')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'email',
          value: itemValue
        });
      }
      // Mobile, tel, fax 
      else if (itemValue.startsWith('TEL')) {
        const [meta, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: !meta.toLowerCase().includes('fax') ? 'mobile' : 'fax',
          value: itemValue
        });
      }
      // URL 
      else if (itemValue.startsWith('URL')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'url',
          value: itemValue
        });
      }
      // Address 
      else if (itemValue.startsWith('ADR')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':').replace(/;/g, ' ').trim();

        properties.push({
          version: 'address',
          value: itemValue
        });
      }
      // Title 
      else if (itemValue.startsWith('TITLE')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'title',
          value: itemValue
        });
      }
      // Company
      else if (itemValue.startsWith('ORG')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'company',
          value: itemValue
        });
      }
      // Birthday
      else if (itemValue.startsWith('BDAY')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'birthday',
          value: new OnesyDate(new Date(`${itemValue.slice(4, 6)}-${itemValue.slice(6, 8)}-${itemValue.slice(0, 4)}`)).milliseconds
        });
      }
      // Date 
      else if (itemValue.startsWith('REV')) {
        const [, ...other] = itemValue.split(':');

        itemValue = other.join(':');

        properties.push({
          version: 'date',
          value: new OnesyDate(new Date(itemValue)).milliseconds
        });
      }
    });

    const objectProperties: any = {};

    properties.forEach((item: any) => {
      let property: any;
      const version = item.version;

      if (['mobile', 'tel', 'fax'].includes(version)) property = 'tels';
      else if (['email'].includes(version)) property = 'emails';
      else if (['title'].includes(version)) property = 'titles';
      else if (['url'].includes(version)) property = 'urls';
      else if (['address'].includes(version)) property = 'addresses';
      else if (['company'].includes(version)) property = 'companies';
      else if (['birthday'].includes(version)) property = 'birthdays';
      else if (['date'].includes(version)) property = 'dates';
      else if (['custom'].includes(version)) property = 'custom';

      if (!objectProperties[property]) objectProperties[property] = [];

      objectProperties[property].push(item);
    });

    object.properties = objectProperties;

    values.push(object);
  });

  return values;
};

export const exportVCard = (items: IContact[]) => {
  const values: any = [];

  items.forEach(contact => {
    let value = `BEGIN:VCARD
VERSION:3.0\n`;

    // Full name 
    const name = textToInnerHTML(contact.name);

    if (name) {
      value += `FN:${name}\n`;

      const nameParts = name.split(' ').slice(0, 4);

      value += `N:${nameParts.join(';')}${';'.repeat(4 - nameParts.length)}\n`;
    }

    // Nickname 
    if (contact.nickname) value += `NICKNAME:${contact.nickname}\n`;

    const description = contact.description ? getElementText(contact.description) : '';

    if (description) value += `NOTE:${description}\n`;

    Object.keys(contact.properties || {}).forEach(key => {
      const values: any = contact.properties?.[key] || [];

      values.forEach((item: any) => {
        const version = item.version!;

        // Email 
        if (version === 'email') value += `EMAIL;TYPE=INTERNET:${item.value}\n`;

        // Mobile, tel, fax 
        if (['mobile', 'tel', 'fax'].includes(version)) value += `TEL;TYPE=${['mobile', 'tel'].includes(version) ? 'CELL' : 'FAX'}:${item.value}\n`;

        // URL 
        if (version === 'url') value += `URL;TYPE=WORK:${item.value}\n`;

        // Address 
        if (version === 'address') value += `ADR;TYPE=home:;;${item.value};;;;\n`;

        // Title 
        if ((version as any) === 'title') value += `TITLE:${item.value}\n`;

        // Company
        if (version === 'company') value += `ORG:${item.value}\n`;

        // Birthday
        if (version === 'birthday') value += `BDAY:${format(new OnesyDate(item.value), `YYYYMMDD`)}\n`;

        // Date 
        if (version === 'date') value += `REV:${new OnesyDate(item.value).iso}\n`;

        // Custom 
        if (version === 'custom') value += `NOTE:${item.value}\n`;
      });
    });

    // End 
    value += `CATEGORIES:myContacts
END:VCARD`;

    values.push(value);
  });

  return values.join('\n');
};

export const getRootPage = (signedIn: any) => {
  const features = signedIn?.user.is;

  if (getSettings(signedIn, 'settings.main') === 'task' && features?.app_task) return '/tasks';

  if (getSettings(signedIn, 'settings.main') === 'note' && features?.app_note) return '/notes';

  if (getSettings(signedIn, 'settings.main') === 'urlShortener' && features?.app_urlShortener) return '/url-shorteners';

  if (getSettings(signedIn, 'settings.main') === 'website' && features?.app_website) return '/websites';

  if (getSettings(signedIn, 'settings.main') === 'chat' && features?.app_chat) return '/chats';

  if (getSettings(signedIn, 'settings.main') === 'contact' && features?.app_contact) return '/contacts';

  if (getSettings(signedIn, 'settings.main') === 'location' && features?.app_location) return '/locations';

  return '/';
};

export const colorOptions: any = {};

Object.keys(colors).forEach((item: any) => {
  const color = (colors[item as 'green'])['A200'] || (colors[item as 'green'])['400'] || colors[item as 'white'];

  colorOptions[item] = {
    name: cleanValue(item, { capitalize: true }),
    color,
    value: item
  };
});

export const dates = ['added_at', 'updated_at', 'received_at', 'removed_at', 'resolved_at', 'starts_at', 'ends_at', 'created'];

export const booleans = ['active', 'archived', 'pinned', 'private', 'default', 'main', 'onesy', 'google', 'resolved'];

export const references = ['contacts', 'contact_groups', 'users'];

const itemToURLMap: any = {
  media: '/library',
  task: '/tasks',
  note: '/notes',
  urlShortener: '/url-shorteners',
  website: '/websites',
  chat: '/chats',
  contact: '/contacts',
  location: '/locations'
};

export const getAppRootURL = (app: any, url: any, signedIn: ISignedIn): string => {
  const features: any = signedIn.user.is;

  if (!features[app]) {
    const value = itemToURLMap[app.replace('app_', '')];

    if (is('string', value)) return value;

    for (const item of Object.keys(value)) {
      if (features[item]) return value[item];
    }
  }

  return url;
};

export const languages = {
  speech: [
    ['Afrikaans', ['af-ZA']],
    ['Bahasa Indonesia', ['id-ID']],
    ['Bahasa Melayu', ['ms-MY']],
    ['Català', ['ca-ES']],
    ['Čeština', ['cs-CZ']],
    ['Deutsch', ['de-DE']],
    ['English', ['en-AU', 'Australia'],
      ['en-CA', 'Canada'],
      ['en-IN', 'India'],
      ['en-NZ', 'New Zealand'],
      ['en-ZA', 'South Africa'],
      ['en-GB', 'United Kingdom'],
      ['en-US', 'United States']],
    ['Español', ['es-AR', 'Argentina'],
      ['es-BO', 'Bolivia'],
      ['es-CL', 'Chile'],
      ['es-CO', 'Colombia'],
      ['es-CR', 'Costa Rica'],
      ['es-EC', 'Ecuador'],
      ['es-SV', 'El Salvador'],
      ['es-ES', 'España'],
      ['es-US', 'Estados Unidos'],
      ['es-GT', 'Guatemala'],
      ['es-HN', 'Honduras'],
      ['es-MX', 'México'],
      ['es-NI', 'Nicaragua'],
      ['es-PA', 'Panamá'],
      ['es-PY', 'Paraguay'],
      ['es-PE', 'Perú'],
      ['es-PR', 'Puerto Rico'],
      ['es-DO', 'República Dominicana'],
      ['es-UY', 'Uruguay'],
      ['es-VE', 'Venezuela']],
    ['Euskara', ['eu-ES']],
    ['Français', ['fr-FR']],
    ['Galego', ['gl-ES']],
    ['Hrvatski', ['hr_HR']],
    ['IsiZulu', ['zu-ZA']],
    ['Íslenska', ['is-IS']],
    ['Italiano', ['it-IT', 'Italia'],
      ['it-CH', 'Svizzera']],
    ['Magyar', ['hu-HU']],
    ['Nederlands', ['nl-NL']],
    ['Norsk bokmål', ['nb-NO']],
    ['Polski', ['pl-PL']],
    ['Português', ['pt-BR', 'Brasil'],
      ['pt-PT', 'Portugal']],
    ['Română', ['ro-RO']],
    ['Slovenčina', ['sk-SK']],
    ['Suomi', ['fi-FI']],
    ['Svenska', ['sv-SE']],
    ['Türkçe', ['tr-TR']],
    ['български', ['bg-BG']],
    ['Pусский', ['ru-RU']],
    ['Српски', ['sr-RS']],
    ['한국어', ['ko-KR']],
    ['中文', ['cmn-Hans-CN', '普通话 (中国大陆)'],
      ['cmn-Hans-HK', '普通话 (香港)'],
      ['cmn-Hant-TW', '中文 (台灣)'],
      ['yue-Hant-HK', '粵語 (香港)']],
    ['日本語', ['ja-JP']],
    ['Lingua latīna', ['la']]
  ]
};

export const isHex = (value: string) => {
  return value && Boolean(value.match(/^0x[0-9a-f]+$/i)) && Number.isInteger(+value);
};

export const priorityToColor = (value: any) => {
  if (value === 'low') return colors.lightblue[500];

  if (value === 'medium') return colors.orange[500];

  if (value === 'important') return colors.red[500];

  return colors.gray[500];
};

export const formatNumber = (value_ = 0, toFixed = undefined) => {
  const value = toFixed !== undefined ? value_.toFixed(toFixed) : value_;

  const hasDecimal = String(value).split('.');

  return [numberWithCommas(hasDecimal[0]), hasDecimal[1]].filter(Boolean).join(',');
};

export const referenceToObject = (value: any) => {
  if (!value) return value;

  if (is('array', value)) return value.map((item: any) => ({
    id: item.id,
    name: textToInnerHTML(item.name)
  }));

  return {
    id: value.id || value._id,
    name: textToInnerHTML(value.name)
  };
};

export const makeNewPassword = (length_ = 7): string => {
  const length = clamp(length_, 4);
  let result = '';

  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const specials = '@#$^&_|/~';
  const numbers = '0123456789';

  const lowPriorityLength = Math.ceil(length * 0.1);

  result += pick(lowercase, (length - (lowPriorityLength * 3)));
  result += pick(uppercase, lowPriorityLength);
  result += pick(specials, lowPriorityLength);
  result += pick(numbers, lowPriorityLength);

  return shuffle(result) as string;
};

export const getUIItems = (items: any, urlPre: string, urlPost: string, other: any) => {
  const {
    navigate
  } = other;

  const weight = 400;

  if (!items?.length) return (
    <Type
      version='t2'

      weight={weight}
    >
      Not provided
    </Type>
  );

  return (
    <Line
      gap={0}

      direction='row'

      align='center'

      wrap='wrap'

      style={{
        maxWidth: 400
      }}
    >
      {items.map(item => {
        const name = textToInnerHTML(item.name);

        let urlEnd = `/${item.id}`;

        if (other.search) {
          urlEnd = `?name=${name}`
        }

        if (urlPost) urlEnd = `${urlEnd}${urlPost}`;

        const Element = navigate ? Link : Type;

        return (
          <Element
            key={item.id}

            version='t2'

            weight={weight}

            onClick={() => navigate && navigate(`${urlPre}${urlEnd}`)}

            {...other?.propsType}
          >
            {textToInnerHTML(item.name)}
          </Element>
        );
      }).flatMap((item, index) => [item, index < items.length - 1 ? <Type version='t2' weight={weight} style={{ marginRight: 6 }}>,</Type> : undefined].filter(Boolean))}
    </Line>
  );
};

export const optionsCategoryTypes = ['session', 'class', 'package', 'membership', 'payment', 'charge', 'expense', 'recipe', 'exercise'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsTransactionType = ['payment', 'charge', 'expense'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsPaymentWith = ['cash', 'card', 'cheque', 'paypal', 'stripe', 'bank transfer', 'other'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsTransactionFor = ['salary', 'rent', 'equipment', 'investment', 'personal', 'travel', 'other'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsDiscountType = ['percentage', 'fixed'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsPayUnit = ['booking', 'hour', 'week', 'month', 'year'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsCustomerStatus = ['active', 'inactive', 'lead'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsDuration = ['minute', 'hour', 'day', 'week', 'month', 'year'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsGender = ['male', 'female', 'other'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsMuscles = ['abdominals', 'abductors', 'adductors', 'biceps', 'calves', 'chest', 'forearms', 'glutes', 'hamstrings', 'lats', 'lower back', 'middle back', 'neck', 'quadriceps', 'shoulders', 'traps', 'triceps'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsForce = ['pull', 'push', 'static'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsLevel = ['expert', 'intermediate', 'beginner'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsMechanic = ['compound', 'isolation'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsEquipment = ['bands', 'barbell', 'body only', 'cable', 'e-z curl bar', 'exercise ball', 'foam roll', 'machine', 'medicine ball'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsDayTime = ['morning', 'afternoon', 'evening', 'night'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsWorkoutStepType = ['exercise', 'rest'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const ingredientNutritionProperties = ['Water (g)', 'Calories (kcal)', 'Protein (g)', 'Lipid total (g)', 'Ash (g)', 'Carbohydrt (g)', 'Fiber total dietary (g)', 'Sugar total (g)', 'Calcium (mg)', 'Iron (mg)', 'Magnesium (mg)', 'Phosphorus (mg)', 'Potassium (mg)', 'Sodium (mg)', 'Zinc (mg)', 'Copper (mg)', 'Manganese (mg)', 'Selenium (µg)', 'Vitamin C (mg)', 'Thiamin (mg)', 'Riboflavin (mg)', 'Niacin (mg)', 'Panto Acid (mg)', 'Vitamin B6 (mg)', 'Folate total (µg)', 'Folic Acid (µg)', 'Food Folate (µg)', 'Folate DFE (µg)', 'Choline total (mg)', 'Vitamin B12 (µg)', 'Vitamin A international unit', 'Vitamin A retinol activity equivalents', 'Retinol (µg)', 'Alpha Carot (µg)', 'Beta Carot (µg)', 'Beta Crypt (µg)', 'Lycopene (µg)', 'LutandZea (µg)', 'Vitamin E (mg)', 'Vitamin D (µg)', 'Vitamin D international unit', 'Vitamin K (µg)', 'Saturated fatty acids (g)', 'Fatty acids Mono (g)', 'Fatty acids Poly (g)', 'Cholesterol (mg)', 'Common serving size (g)', 'Common serving size description', 'Common serving size alternative (g)', 'Common serving size alternative description', 'Percentage of inedible parts'];

export const optionsRecipesDifficulty = ['very easy', 'easy', 'medium', 'difficult'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsPurchaseType = ['package', 'membership'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsSessionsClassesUnit = ['total', 'day', 'week', 'month', 'year'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const propertyCalories = 'Calories (kcal)';

export const optionsBookingType = ['session', 'class', 'consultation'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsBookingStatusUpdate = ['booked', 'completed', 'postponed', 'cancelled'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsGoalStatus = ['active', 'completed', 'paused', 'pending', 'postponed', 'cancelled', 'other'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsFormInputType = ['text', 'slider', 'radios', 'checkboxes', 'date-time', 'date', 'time', 'select', 'rating', 'confirm'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsReviewSocialMedia = ['Instagram', 'Tiktok', 'Facebook', 'LinkedIn'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsBookingIntegrations = ['google-calendar', 'google-meet'].map(item => ({
  name: cleanValue(item, { capitalize: true }),
  value: item
}));

export const optionsReports = ['profit', 'clients', 'employees', 'transactions'].map(item => ({
  name: cleanValue(item, { capitalize: true }),
  value: item
}));

export const optionsPropertyType = ['text', 'number'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const optionsPropertyFor = ['general', 'client'].map(item => ({
  name: capitalize(item),
  value: item
}));

export const getID = () => {
  let id: any = window.location.pathname.split('/').filter(Boolean).slice(-1)[0];

  id = isHex(`0x${id}`) ? id : undefined;

  return id;
};

export const repeats = (day: OnesyDate, value: any, dateProperty = 'ends_at', repeatCount) => {
  const dateValue = value[dateProperty];

  if (!value?.repeat?.active || !dateValue) return;

  const date = new OnesyDate(dateValue);

  let unit = value?.repeat?.unit;
  let valueRepeat = value?.repeat?.value;

  if (unit === 'day') unit = 'days';

  if (unit === 'week') {
    unit = 'days';

    valueRepeat *= 7;
  }

  if (unit === 'month') unit = 'months';

  let difference = diff(day, date, unit);

  let repeating = false;

  if (['day', 'week'].includes(value?.repeat?.unit)) {
    if (
      value?.repeat.unit === 'week' &&
      !!value?.repeat?.weekdays?.length
    ) {
      difference = diff(day, date.dayWeek === 0 ? add(-1, 'week', date) : date, 'weeks');

      if (day.dayWeek === 0) difference -= 1;

      const modulus = difference % value?.repeat?.value;

      repeating = isDate(day, 'after or same', date) && !modulus && value?.repeat.weekdays?.includes(day.dayWeek);
    }
    else {
      const modulus = difference % valueRepeat;

      repeating = isDate(day, 'after or same', date) && !modulus;
    }
  }

  if (['month'].includes(value?.repeat?.unit)) {
    const modulus = difference % valueRepeat;

    const monthDate = add(difference, 'month', date);

    repeating = (monthDate.year === day.year && monthDate.dayYear === day.dayYear) && !modulus;
  }

  if (['year'].includes(value?.repeat?.unit)) {
    const modulus = difference % valueRepeat;

    const yearDate = add(difference, 'year', date);

    repeating = isDate(day, 'after', date) && (yearDate.year === day.year && yearDate.dayYear === day.dayYear) && !modulus;
  }

  if (
    (value?.repeat?.skip_weekends) &&
    [0, 6].includes(day.dayWeek)
  ) repeating = false;

  if (repeating) {
    const formated = getDate(day);

    if (value?.repeat?.ends?.active) {
      // date
      if (value?.repeat.ends.version === 'date') {
        const endsDate = new OnesyDate(value?.repeat.ends.value);

        repeating = repeating && (
          (day.year < endsDate.year) ||
          (
            day.year === endsDate.year &&
            (
              (day.month < endsDate.month) ||
              (
                day.month === endsDate.month &&
                day.dayYear <= endsDate.dayYear
              )
            )
          )
        );
      }
    }

    if (repeating) {
      if (!repeatCount[value?.id]) repeatCount[value?.id] = [];

      if (!repeatCount[value?.id].includes(formated)) repeatCount[value?.id].push(formated);
    }

    if (value?.repeat?.ends?.active) {
      const indexRepeated = repeatCount[value?.id]?.indexOf(formated);

      // count
      if (value?.repeat.ends.version === 'count') repeating = repeating && value?.repeat.ends.value >= ((indexRepeated === -1 ? 0 : indexRepeated) + 1);
    }

    repeating = !value?.repeat.ends?.active || repeating;
  }

  return repeating;
};

interface IAudioFix {
  blob: Blob;
  duration?: number;
}

export const durationFallback = (blob: Blob): Promise<IAudioFix> => new Promise(resolve => {
  try {
    const audio = new Audio();

    const fileURL = URL.createObjectURL(blob);

    audio.src = fileURL;

    audio.playbackRate = 4;

    audio.muted = true;

    let duration = 0;

    audio.addEventListener('timeupdate', () => {
      if (is('number', audio.currentTime)) duration = audio.currentTime;
    });

    // Listen for when the audio ends
    audio.addEventListener('ended', () => {
      // Clean up the object URL
      URL.revokeObjectURL(fileURL);

      console.log('Audio ended', audio.playbackRate, duration);

      resolve({ blob, duration });
    });

    // Play the audio
    audio.play()
      .then(() => {
        console.log('Audio started');
      })
      .catch(error => {
        console.log('Audio play error', error);

        return resolve(null);
      });
  }
  catch (errorFallback) {
    return resolve(null);
  }
});

export const audioFix = async (blob: Blob): Promise<IAudioFix> => {
  try {
    const readAsArrayBuffer = () => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.readAsArrayBuffer(blob);

        fileReader.onloadend = () => resolve(fileReader.result);

        fileReader.onerror = (event: any) => reject(event.error);;
      });
    };

    const arrayBuffer = await readAsArrayBuffer() as any;

    const decoder = new ebml.Decoder();

    const reader = new ebml.Reader();

    reader.logging = false;

    reader.drop_default_duration = false;

    const uint8Array = new Uint8Array(arrayBuffer);

    const elements = decoder.decode(uint8Array);

    elements.forEach((element) => reader.read(element));

    reader.stop();

    const refinedMetadataBuf = ebml.tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);

    const body = arrayBuffer.slice(reader.metadataSize);

    const result = new Blob([refinedMetadataBuf, body], { type: blob.type });

    return { blob: result };
  }
  catch (error) {
    console.log('audioFix error', error);

    // fallback 
    return durationFallback(blob);
  }
};

export const toRemoveProperties = ['added_at', 'id', 'onesy', 'organization', 'project', 'updated_at', 'user'];

export const mapExcalidrawProperties = {
  id: 'i',
  type: 't',
  x: 'x',
  y: 'y',
  width: 'w',
  height: 'h',
  angle: 'a',
  strokeColor: 'stc',
  backgroundColor: 'b',
  fillStyle: 'f',
  strokeWidth: 'stw',
  strokeStyle: 'ss',
  roughness: 'r',
  opacity: 'o',
  groupIds: 'gi',
  frameId: 'fi',
  roundness: 'ro',
  seed: 's',
  version: 'v',
  versionNonce: 'vn',
  isDeleted: 'id',
  boundElements: 'be',
  updated: 'u',
  link: 'l',
  locked: 'lo',
  customData: 'c',
  status: 'st',
  fileId: 'fid',
  scale: 'sc',
  text: 'te',
  fontSize: 'fs',
  fontFamily: 'ff',
  textAlign: 'ta',
  verticalAlign: 'va',
  baseline: 'bl',
  containerId: 'ci',
  originalText: 'ot',
  lineHeight: 'lh',
  showWelcomeScreen: 'sws',
  theme: 'tm',
  collaborators: 'cl',
  currentChartType: 'cct',
  currentItemBackgroundColor: 'cibc',
  currentItemEndArrowhead: 'cieah',
  currentItemFillStyle: 'cifs',
  currentItemFontFamily: 'ciff',
  currentItemFontSize: 'cifsi',
  currentItemOpacity: 'cio',
  currentItemRoughness: 'cir',
  currentItemStartArrowhead: 'cisa',
  currentItemStrokeColor: 'cisc',
  currentItemRoundness: 'ciro',
  currentItemStrokeStyle: 'ciss',
  currentItemStrokeWidth: 'cisw',
  currentItemTextAlign: 'cita',
  cursorButton: 'cb',
  activeEmbeddable: 'ae',
  draggingElement: 'de',
  editingElement: 'ee',
  editingGroupId: 'egi',
  editingLinearElement: 'ele',
  activeTool: 'at',
  customType: 'ct',
  lastActiveTool: 'lat',
  penMode: 'pm',
  penDetected: 'pd',
  errorMessage: 'em',
  exportBackground: 'eb',
  exportScale: 'es',
  exportEmbedScene: 'ems',
  exportWithDarkMode: 'ewdm',
  fileHandle: 'fh',
  gridSize: 'gs',
  isBindingEnabled: 'ibe',
  defaultSidebarDockedPreference: 'dsdp',
  isLoading: 'il',
  isResizing: 'ir',
  isRotating: 'iro',
  lastPointerDownWith: 'lpdw',
  multiElement: 'me',
  name: 'na',
  contextMenu: 'cm',
  openMenu: 'om',
  openPopup: 'op',
  openSidebar: 'os',
  openDialog: 'od',
  pasteDialog: 'pdi',
  shown: 'sw',
  data: 'da',
  previousSelectedElementIds: 'psei',
  resizingElement: 're',
  scrolledOutside: 'so',
  scrollX: 'sx',
  scrollY: 'sy',
  selectedElementIds: 'sei',
  selectedGroupIds: 'sgi',
  selectedElementsAreBeingDragged: 'seabd',
  selectionElement: 'se',
  shouldCacheIgnoreZoom: 'sciz',
  showStats: 'sst',
  startBoundElement: 'sbe',
  suggestedBindings: 'sbi',
  frameRendering: 'fr',
  enabled: 'e',
  clip: 'cli',
  outline: 'out',
  frameToHighlight: 'fth',
  editingFrame: 'ef',
  elementsToHighlight: 'eth',
  toast: 'to',
  viewBackgroundColor: 'vbc',
  zenModeEnabled: 'zme',
  zoom: 'z',
  value: 'vu',
  viewModeEnabled: 'vme',
  pendingImageElementId: 'piei',
  showHyperlinkPopup: 'shp',
  selectedLinearElement: 'sle',
  snapLines: 'sl',
  originSnapOffset: 'oso',
  objectsSnapModeEnabled: 'osme',
  offsetLeft: 'ol',
  offsetTop: 'oto'
};

export const mapExcalidrawPropertiesReverse = {};

Object.keys(mapExcalidrawProperties).forEach(key => {
  const value = mapExcalidrawProperties[key];

  mapExcalidrawPropertiesReverse[value] = key;
});

export const fromExcalidrawProperties = (object: any) => {
  const result = {};

  Object.keys(object).forEach(key => {
    const newKey = mapExcalidrawProperties[key];

    result[newKey || key] = object[key];

    if (is('object', object[key])) {
      const nestedKeys = Object.keys(object[key]);

      result[newKey] = {};

      nestedKeys.forEach(nestedKey => {
        result[newKey][mapExcalidrawProperties[nestedKey] || nestedKey] = object[key][nestedKey];
      });
    }
  });

  return result;
};

export const toExcalidrawProperties = (object: any) => {
  const result: any = {};

  Object.keys(object).forEach(key => {
    const newKey = mapExcalidrawPropertiesReverse[key];

    result[newKey || key] = object[key];

    if (is('object', object[key])) {
      const nestedKeys = Object.keys(object[key]);

      result[newKey] = {};

      nestedKeys.forEach(nestedKey => {
        result[newKey][mapExcalidrawPropertiesReverse[nestedKey] || nestedKey] = object[key][nestedKey];
      });
    }
  });

  return result;
};

export const isScreenShare = (stream: MediaStream) => {
  const videoTrack = stream?.getVideoTracks()?.[0];

  if (videoTrack) {
    const trackLabel = videoTrack.label.toLowerCase();

    return trackLabel.includes('screen') || trackLabel.includes('display');
  }

  return false;
};

export const usesStreamVideo = (stream: MediaStream) => {
  const videoTracks = stream?.getVideoTracks();

  return videoTracks && videoTracks.length > 0 && videoTracks[0].enabled;
};

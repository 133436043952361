import React from 'react';

import { cleanValue, textToInnerHTML } from '@onesy/utils';
import { Line, LineChart, Medias, Type } from '@onesy/ui-react';

import { Input, Inputs, ModalForm } from 'ui';
import { AppService } from 'services';
import { getDate } from 'utils';

const Element = React.forwardRef((props: any, ref: any) => {
  const {
    object
  } = props;

  const [loaded, setLoaded] = React.useState(false);

  const init = React.useCallback(() => {
    setTimeout(() => {
      setLoaded(true);
    });
  }, []);

  React.useEffect(() => {
    // init 
    init();
  }, []);

  const onClose = React.useCallback(() => {
    AppService.pages.addSecondary.emit({
      ...AppService.pages.addSecondary.value,

      open: false
    });
  }, []);

  const inputPropsRead: any = {
    gap: 0.5,
    size: 'small'
  };

  const modal: any = {
    read: loaded && (
      <Inputs
        gapMain={2}
      >
        {object?.logs?.length > 1 && (
          <Line
            fullWidth

            style={{
              padding: '12px 74px 0px 40px'
            }}
          >
            <LineChart
              names={{
                x: 'Updated at',
                y: 'Progress'
              }}

              nameX

              nameY

              guidelines='both'

              smooth={false}

              values={[
                {
                  color: 'primary',

                  name: 'Progress',

                  values: object.logs.map((item: any) => [item.updated_at, item.value?.value])
                }
              ]}

              labelResolve={(itemValue, axis, item, version) => {
                if (axis === 'x') return getDate(itemValue, version ? 'entire' : 'DD/MM/YYYY HH:mm[h]');

                return `${itemValue} ${object?.value?.unit || ''}`;
              }}

              marksYAutoNumber={7}

              labelsYAutoNumber={7}

              marksXAutoNumber={5}

              labelsXAutoNumber={5}

              points

              pointsVisibility='visible'

              style={{
                width: '100%'
              }}
            />
          </Line>
        )}

        <Input
          name='Goal'

          {...inputPropsRead}
        >
          <Type
            version='b1'
          >
            {`${object?.value?.value || 0} ${object?.value?.unit || ''}`.trim()}
          </Type>
        </Input>

        {object?.starts_at && (
          <Input
            name='Starts at'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {getDate(object.starts_at)}
            </Type>
          </Input>
        )}

        {object?.ends_at && (
          <Input
            name='Ends at'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {getDate(object.ends_at)}
            </Type>
          </Input>
        )}

        {object?.description !== undefined && (
          <Input
            name='Description'

            {...inputPropsRead}
          >
            <Type
              version='b1'

              dangerouslySetInnerHTML={{
                __html: textToInnerHTML(object.description)
              }}
            />
          </Input>
        )}

        {object?.status !== undefined && (
          <Input
            name='Status'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {cleanValue(object.status, { capitalize: true })}
            </Type>
          </Input>
        )}

        {object?.employees !== undefined && (
          <Input
            name='Trainers'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.employees?.map(item => item.name).join(', ')}
            </Type>
          </Input>
        )}

        {object?.employee_groups !== undefined && (
          <Input
            name='Trainer groups'

            {...inputPropsRead}
          >
            <Type
              version='b1'
            >
              {object.employee_groups?.map(item => item.name).join(', ')}
            </Type>
          </Input>
        )}

        <Input
          name='Logs'

          {...inputPropsRead}
        >
          <Type
            version='b1'
          >
            {!object?.logs?.length ? 'No logs' : `${object?.logs?.length} logs`}
          </Type>
        </Input>

        <Input
          name='Latest log'

          {...inputPropsRead}
        >
          <Type
            version='b1'
          >
            {object?.logs?.length ? `${object?.logs?.[object?.logs?.length - 1]?.value?.value || 0} ${object?.logs?.[object?.logs.length - 1]?.value?.unit || ''}`.trim() : ''}
          </Type>
        </Input>

        {!!object?.media?.length && (
          <Input
            name='Media'

            {...inputPropsRead}
          >
            <Medias
              values={object.media.map(item => ({
                value: item
              }))}
            />
          </Input>
        )}
      </Inputs>
    )
  };

  return (
    <ModalForm
      {...props}

      object={object}

      add

      onClose={onClose}
    >
      {modal.read}
    </ModalForm>
  );
});

export default Element;
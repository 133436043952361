import React from 'react';

import { is, textToInnerHTML } from '@onesy/utils';
import { Slide, useMediaQuery } from '@onesy/ui-react';
import { IconButton, Line, Modal, ModalHeader, ModalTitle, Surface, Tooltip } from '@onesy/ui-react';
import { classNames, style } from '@onesy/style-react';
import { TMethod } from '@onesy/models';
import OnesySubscription from '@onesy/subscription';

import IconMaterialKeyboardArrowDown from '@onesy/icons-material-rounded-react/IconMaterialKeyboardArrowDownW100';

import { AppService } from 'services';

const useStyle = style(theme => ({
  root: {
    '&.onesy-Surface-root': {
      background: 'transparent'
    }
  },

  root_modal: {

  },

  color_primary: {

  },

  color_secondary: {
    '&.onesy-Modal-surface.onesy-Surface-root': {
      background: theme.palette.color.primary[theme.palette.light ? 100 : 1]
    }
  },

  header: {
    position: 'relative',
    padding: '12px 12px 12px 24px',
    minHeight: 40,

    '&.onesy-ModalHeader-root': {
      padding: 0
    }
  },

  headerName: {
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    alignSelf: 'center'
  },

  padding: {
    padding: 24
  },

  paddingSmall: {
    padding: 12
  },

  surface: {
    '&.onesy-Surface-root': {
      padding: 0,
      background: theme.palette.color.primary[theme.palette.light ? 99 : 5],

      '& .onesy-ModalHeader-root': {
        paddingBottom: 0
      }
    }
  },

  version_inline: {
    width: '100%'
  },

  maxHeight: {
    height: 'calc(100% - 80px)'
  }
}), { name: 'onesy-page-Add' });

export interface IAdd {
  id?: string;

  add?: boolean;

  hash?: string;

  singular?: string;

  version?: 'inline' | 'mid' | 'entire';

  minWidth?: 'xss' | 'xs' | 'sm' | 'rg' | 'lg' | 'xl' | 'xxl';

  color?: 'primary' | 'secondary';

  type?: 'add' | 'addSecondary' | 'addTertiary';

  name?: any;

  title?: any;

  subscription?: OnesySubscription;

  onConfirm?: TMethod;

  onClose?: TMethod;

  open?: boolean;

  maxHeight?: boolean;

  helpers?: boolean;

  to?: string;

  secondary?: boolean;

  route?: {
    previous: any;
    to: any;
  };

  [p: string]: any;
}

const Element = React.forwardRef((props: IAdd, ref: any) => {
  const {
    open: open_,

    id,

    hash,

    color = 'primary',

    version = 'mid',

    minWidth = 'lg',

    singular,

    name,

    title,

    to,

    secondary,

    route,

    maxHeight,

    helpers = !props.children,

    subscription = AppService.pages.add,

    onConfirm,

    onClose: onClose_,

    props: moreProps,

    className,

    children,

    ...other
  } = props;

  const { classes } = useStyle();

  const mobile = useMediaQuery('(max-width: 1023px)');

  const [open, setOpen] = React.useState<any>(false);

  const refs = {
    route: React.useRef(route),
    to: React.useRef(to)
  };

  refs.route.current = route;

  refs.to.current = to;

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const openRoute = React.useCallback(() => {
    if (secondary) return;

    if (refs.to.current !== undefined) {
      const newRoute = window.location.pathname.endsWith(`/${refs.to.current}`) ? window.location.pathname : `${window.location.pathname}/${refs.to.current}`;

      if (window.location.pathname !== newRoute) window.history.pushState(undefined, '', newRoute);
    }
  }, [secondary]);

  const closeRoute = React.useCallback(() => {
    if (secondary) return;

    if (refs.to.current !== undefined) {
      const previousRoute = window.location.pathname.replace(`/${refs.to.current}`, '');

      if (window.location.pathname !== previousRoute) window.history.pushState(undefined, '', previousRoute);
    }
  }, [secondary]);

  const onClose = React.useCallback(() => {
    if (open) {
      setOpen(false);

      subscription.emit({
        ...subscription.value,

        secondary,

        open: false
      });
    }

    closeRoute();

    if (is('function', onClose_)) onClose_!();
  }, [subscription, secondary, onClose_, open]);

  React.useEffect(() => {
    if (open) openRoute();
  }, [open]);

  React.useEffect(() => {
    if (open_ !== undefined && open_ !== open) setOpen(open_);

    // open = false 
    if (open && !open_) closeRoute();
  }, [open_]);

  // inline
  if (version === 'inline') return (
    <Line
      ref={ref}

      direction='column'

      align='center'

      justify='space-between'

      color='primary'

      tonal

      Component={Surface}

      className={classNames([
        className,
        classes.root,
        classes[`version_${version}`]
      ])}

      {...other}
    >
      {React.cloneElement(children, {
        ...moreProps,

        onClose: () => {
          onClose();

          if (is('function', children.props.onClose)) children.props.onClose();
        }
      })}
    </Line>
  );

  // modal
  return (
    <Modal
      ref={ref}

      open={open}

      minWidth={minWidth}

      fullScreen={mobile}

      onOpen={onOpen}

      onClose={onClose}

      TransitionComponent={Slide}

      SurfaceProps={{
        tonal: true,
        color: 'primary',

        className: classNames([
          classes.surface,
          !mobile && maxHeight && classes.maxHeight,
          classes[`color_${color}`]
        ])
      }}

      className={classNames([
        'onesy-Add',
        className,
        classes.root_modal
      ])}

      {...other}
    >
      {helpers && (
        <ModalHeader
          gap={0}
        >
          <Line
            direction='row'

            align='center'

            justify='space-between'

            fullWidth

            className={classNames([
              classes.header
            ])}
          >
            <ModalTitle
              version='h3'

              align='left'

              whiteSpace='nowrap'

              className={classes.name}

              dangerouslySetInnerHTML={{
                __html: `${singular ? `${singular}: ` : ''}${textToInnerHTML(name)}`
              }}
            />

            <Line
              gap={0}

              align='center'

              justify='center'

              direction='row'
            >
              <Tooltip
                name='Close'
              >
                <IconButton
                  color='inherit'

                  onClick={onClose}
                >
                  <IconMaterialKeyboardArrowDown
                    size='large'
                  />
                </IconButton>
              </Tooltip>
            </Line>
          </Line>
        </ModalHeader>
      )}

      {children}
    </Modal>
  );
});

export default Element;
